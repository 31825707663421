import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { Button } from '../Button/Button';

import './Section.css';

export const Section = () => {
  return(
    <Container>
      <Grid container spacing={2} className='section'>
          <Grid item xs={12} md={8}>
            <h1 className='section-title'>Can We Help Accomplish Your Goals?</h1>
            <div className='spacer-xs'></div>
            <p className='section-text-reg'>
            We are eager to learn more about your project goals and what we can do to help. Our qualified team provides a variety of services and solutions to make your project a success.
            </p>
          </Grid>
          {/* <div className='section--btns'> */}
            <Grid item xs={12} md={4}className='section--btns'>
              <Button
                className='btns'
                buttonStyle='btn--invert'
                buttonSize='btn--large'
                path='/services'
              >
                How Can We Help You?
              </Button>
            </Grid>
          {/* </div> */}
      </Grid>
    </Container>
  )
}