import React from 'react';
import { Container } from '@mui/material';
import { Grid } from '@material-ui/core';

import './Contact.css';

export const Contact = () => {
  return(
    <Container className='extra'>
      <div className='employment__container'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 className='about-title-left'>Contact Us</h1>
          </Grid>
            <Grid item xs={8}>
              <div className='spacer-xs'></div>
              <p>Our Headquarters are located at: <a href="http://maps.google.com/?q=9253 Onsted Hwy Onsted, MI 49265" target="_blank" rel="noreferrer" className='link-visted'>9253 Onsted Hwy Onsted, MI 49265</a></p>
              <div className='spacer-xs'></div>
              <p>If you have any questions reach out to Tim Davis, SR/WA, MBA Vice President.</p>
              <ul>
                <li><a href="tel:+15176730053" className='link-unstyled'>517.673.0053</a></li>
                <li><a href="mailto:Tim.Davis@4DAcquisition.com" className='link-unstyled'>Tim.Davis@4DAcquisition</a></li>
              </ul>
            </Grid>
        </Grid>
      </div>
    </Container>
  );
}