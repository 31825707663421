import React, { useState } from 'react'
import {
  Nav,
  NavLink,
  NavLinkMobile,
  Bars,
  Close,
  NavMenu,
  NavMenuMobile,
  StyledLogo
  // NavButton,
  // NavButtonLink
} from './NavBar'

import LINKS from '../../utils/links'
import Logo from '../../assets/Logo.jpg'

const Navbar = () => {
  const [click, setClick] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState('-100%')

  const handleMenuClick = () => {
    console.log(click)
    setClick(!click)
    console.log(click)
    setIsMobileOpen(click ? '-100%' : '0')
    console.log(isMobileOpen)
  }

  return (
    <>
      <Nav>
        <NavLink exact="true" to={LINKS.home}>
          {/* <p>Insert Super Duper Cool Logo Here</p> */}
          <StyledLogo id ="logo" src={Logo} alt='Logo'/>
        </NavLink>
        {click ? (
          <Close onClick={handleMenuClick} />
        ) : (
          <Bars onClick={handleMenuClick} />
        )}
        <NavMenu>
          <NavLink exact="true" to={LINKS.home} activeStyle>
            Home
          </NavLink>
          <NavLink to={LINKS.about} activeStyle>
            About
          </NavLink>
          <NavLink to={LINKS.services} activeStyle>
            Services
          </NavLink>
          <NavLink to={LINKS.employment} activeStyle>
            Employment
          </NavLink>
          <NavLink to={LINKS.contact} activeStyle>
            Contact Us
          </NavLink>
          {/* <NavLink to={LINKS.signUp} activeStyle>
            Sign Up
          </NavLink> */}
        </NavMenu>
        {/* <NavButton>
          <NavButtonLink to={LINKS.signIn}>Sign In</NavButtonLink>
        </NavButton> */}
      </Nav>
      <NavMenuMobile style={{ left: isMobileOpen }}>
        <NavLinkMobile to={LINKS.home} onClick={handleMenuClick} activeStyle>
          Home
        </NavLinkMobile>
        <NavLinkMobile to={LINKS.about} onClick={handleMenuClick} activeStyle>
          About
        </NavLinkMobile>
        <NavLinkMobile
          to={LINKS.services}
          onClick={handleMenuClick}
          activeStyle
        >
          Services
        </NavLinkMobile>
        <NavLinkMobile to={LINKS.employment} onClick={handleMenuClick} activeStyle>
          Employment
        </NavLinkMobile>
        <NavLinkMobile to={LINKS.contact} onClick={handleMenuClick} activeStyle>
          Contact Us
        </NavLinkMobile>
        {/* <NavLinkMobile to={LINKS.signUp} onClick={handleMenuClick} activeStyle>
          Sign Up
        </NavLinkMobile> */}
      </NavMenuMobile>
    </>
  )
}

export default Navbar
