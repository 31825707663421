import React from "react";
import { Info, HeroSection, Section } from "components";
import Container from '@mui/material/Container';

export const Home = () => {
  return(
    <>
    <div>
      {/* <Container>
        <Info />
      </Container> */}
      <HeroSection />
      <Section />
    </div>
    </>
  )
}