import React from 'react';
import { Container } from '@mui/material';
import { Grid } from '@material-ui/core';

import { default as powerline6 } from '../../assets/powerline_6.jpg'

import './Employment.css'

export const Employment = () => {
  return(
    <Container className='extra'>
      <div className='employment__container'>
        <Grid container spacing={2}>
            <Grid item xs={8}>
                {/* <h1 className='about'>About Us</h1> */}
                {/* <div className='about__text'> */}
                    <h1 className='about-title-left'>Employment</h1>
                    <div className='spacer-xs'></div>
                    <p>We are currently accepting resumes for local (no per diem) agents in central Michigan and St. Louis (East Metro/ Illinois). </p>
                    <div className='spacer-xs'></div>
                    <p>If interested please submit your resume to <a href="mailto:Resumes@4DAcquisition.com">Resumes@4DAcquisition.com</a></p>
                    <div className='spacer-sm'></div>
                    <small>4D Acquisition &amp; Consulting, LLC, is an equal opportunity employer and offers its employees competitive pay and a 401k program. </small>
                {/* </div> */}
            </Grid>
            <Grid item xs={4} className='employment-img-container'>
                <img src={powerline6} alt="img" className='employment-img'></img>
            </Grid>
        </Grid>
      </div>
    </Container>
  );
}