import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';

import { Button } from '../../components/Button/Button';
import { default as Powerline } from '../../assets/powerline.JPG'
import "./ServiceCards.css";


export const ServiceCards = () => {
  return(
    <Container className='extra'>
      <div className='services__container'>
        <Grid container spacing={2}>
            <Grid item xs={4} className='service-img-container'>
                <img src={Powerline} alt="img" className='services-img'></img>
            </Grid>
            <Grid item xs={8}>
                {/* <h1 className='about'>About Us</h1> */}
                {/* <div className='about__text'> */}
                    <h1 className='about-title-left'>Services</h1>
                    <div className='spacer-xs'></div>
                    <ul>
                      <li>Negotiation and Acquisition</li>
                      <li>Development of Strategy and Approach</li>
                      <li>Project Management</li>
                      <li>Condemnation Support</li>
                      <li>Relocation Assistance (URA)</li>
                      <li>Cost Estimating</li>
                      <li>Construction Liaison</li>
                      <li>Coordinate Survey, Environmental and other field activities</li>
                      <li>Route Selection</li>
                      <li>Right of Entry</li>
                      <li>Valuation: Market Studies, Appraisal &amp; Review</li>
                      <li>Title Search and Curative</li>
                      <li>Data Management</li>
                      <li>Permitting</li>
                      <li>Public Outreach and Meeting Support</li>
                      <li>Preparation of Legal Descriptions</li>
                      <li>Operations Support</li>
                    </ul>
                {/* </div> */}
            </Grid>
        </Grid>
      </div>
    </Container>


    // <Container fluid>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} md={4}>
    //       <div className='card'>
    //         <div className='card-content'>
    //           <h2 className='card-title'>Negotiation and Acquisition</h2>
    //           <p className='card-body'>Some info about said service...</p>
    //           {/* <Button
    //             className='button'
    //             buttonStyle='btn--invert'
    //             buttonSize='btn--large'
    //             path='/services/lawn'
    //           >
    //             See More!
    //           </Button> */}
    //           <a href="/service1" className='button'>
    //             <span>Learn More</span>
    //           </a>
    //         </div>
    //       </div>
    //     </Grid>

    //     <Grid item xs={12} md={4}>
    //       <div className='card'>
    //         <div className='card-content'>
    //           <h2 className='card-title'>Development of Strategy and Approach</h2>
    //           <p className='card-body'>Some info about said service...</p>
    //           {/* <Button
    //             className='button'
    //             buttonStyle='btn--invert'
    //             buttonSize='btn--large'
    //             path='/services/lawn'
    //           >
    //             See More!
    //           </Button> */}
    //           <a href="/service1" className='button'>
    //             <span>Learn More</span>
    //           </a>
    //         </div>
    //       </div>
    //     </Grid>

    //     <Grid item xs={12} md={4}>
    //       <div className='card'>
    //         <div className='card-content'>
    //           <h2 className='card-title'>Project Management</h2>
    //           <p className='card-body'>Some info about said service...</p>
    //           {/* <Button
    //             className='button'
    //             buttonStyle='btn--invert'
    //             buttonSize='btn--large'
    //             path='/services/lawn'
    //           >
    //             See More!
    //           </Button> */}
    //           <a href="/service1" className='button'>
    //             <span>Learn More</span>
    //           </a>
    //         </div>
    //       </div>
    //     </Grid>

    //   </Grid>
    // </Container>
  )
}