import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FaLinkedin, FaGithubSquare } from 'react-icons/fa'
// import COLORS from '../../utils/colors'
// import LINKS from '../../utils/links'

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  > a {
    color: white;
    text-decoration: none;
  }
`

const Footer = () => {
  return (
    // blue color: 43D7FC
    <div style={{ backgroundColor: `#000000` }}>
      
      <div
        style={{
          backgroundColor: 'black', // was uing COLORS.primary -- if switching back to old blue above use primary here.
          textAlign: 'center',
          padding: '.5rem',
          color: 'white'
        }}
      >
        Copyright &copy; {new Date().getFullYear()} 4D Acquisition &amp; Consulting, LLC.
        All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer
