// import logo from './logo.svg';
import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, About, Aboutv2, Services, ServiceCards, Employment, Contact, Error404 } from "pages";
import Footer from './components/Footer/Footer'
// import Footerv2 from './components/footerv2/FooterV2'
import { Footerv2 } from "components";
import NavBar from './components/NavBar'
import ScrollToTop from "utils/ScrollToTop";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <div className='page-container'>
        <div className='content-wrap'>
          <Routes>
              <Route path='/' exact element={<Home />} />
              {/* <Route path='/about' element={<About />} /> */}``
              <Route path='/about' element={<Aboutv2 />} />
              <Route path='/services' element={<ServiceCards />} />
              <Route path='/employment' element={<Employment />} />
              <Route path='/contact' element={<Contact />} />
              <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
        {/* <Footer /> */}
        <Footerv2 />
        </div>
    </Router>
  );
}

export default App;
