import { FaBars, FaTimes } from 'react-icons/fa'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'
import COLORS from '../../utils/colors'

export const Nav = styled.nav`
  background: #000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
  position: sticky;
  top: 0;
  z-index: 999;
  /* Third Nav */
  /* justify-content: flex-start; */
`

export const NavMenuMobile = styled.div`
  /* display: none; */
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  position: fixed;
  background-color: black;
  opacity: 0.9;
  transition: all 1s ease;
  overflow-x: hidden;
  padding-top: 20px;
  z-index: 2;
  /* @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    position: fixed;
    background-color: black;
    opacity: 0.9;
    transition: all 1s ease;
    overflow-x: hidden;
    padding-top: 20px;
    z-index: 2;
    left: -100%;
  } */
  @media screen and (max-width: 1097px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    position: fixed;
    background-color: black;
    opacity: 0.9;
    transition: all 1s ease;
    overflow-x: hidden;
    padding-top: 20px;
    z-index: 2;
    left: -100%;
  }
`

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: ${COLORS.primary};
  }
`

export const NavLinkMobile = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 80px;
  cursor: pointer;
  transition: all 500ms ease;
  /* &.active {
    color: #fff;
  } */
  &:hover {
    background-color: ${COLORS.primary};
  }
`

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  /* @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer; */
    @media screen and (max-width: 1097px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const Close = styled(FaTimes)`
  display: none;
  color: #fff;
  /* @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer; */
    @media screen and (max-width: 1097px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  /* @media screen and (max-width: 768px) {
    display: none;
  } */
  @media screen and (max-width: 1097px) {
    display: none;
  }
`

export const NavButton = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  /* @media screen and (max-width: 768px) {
    display: none;
  } */
  @media screen and (max-width: 1097px) {
    display: none;
  }
`

export const NavButtonLink = styled(Link)`
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`

export const StyledLogo = styled.img`
  height: 50px;
  width: auto;
`