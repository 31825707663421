import { Container } from '@mui/material';
import React from 'react';
// import '../App.css';
import { Button } from '../Button/Button';
// import landingLogo from '../../public/images/landingLogo.jpg'
import './HeroSection.css';

export function HeroSection() {
  return (
    <div className='hero-container'>
      <Container>
        <h1 className='hero-container-title'>4D Aquisition &amp; Consulting, LLC</h1>
        <p className='hero-container-small'><strong>4D Acquisition and Consulting, LLC</strong> offers the expertise to support the land acquisition and management for your energy and infrastructure projects and ongoing operation support.</p>
        {/* <div className='spacer-md'></div>
        <h3 className='hero-container-sub-title'>Can We Help Accomplish Your Goals?</h3>
        <p className='hero-container-small'>We are eager to learn more about your project goals and what we can do to help.  Our qualified team provides a variety of services and solutions to make your project a success. </p> */}
      </Container>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          path='/about'
        >
          Learn More
        </Button>
        {/* <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--large'
            path='/schedule'
        >
            How Can We Help You? <i className="fas fa-calendar-check" />
        </Button> */}
      </div>
    </div>
  );
}

// export default HeroSection;
