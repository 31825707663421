const LINKS = {
  home: '/',
  about: '/about',
  services: '/services',
  employment: '/employment',
  contact: '/contact',
  signUp: '/signUp',
  signIn: '/signIn',
  seEngineeringPracticeIMS:
    'https://github.com/JarrenJ/SE-Internship-Management-System',
  webstersLawnCare: 'https://github.com/JarrenJ/websterslawncare',
  jeopardy: 'https://github.com/JarrenJ/ScuffedJeopardy_2.0'
}

export default LINKS
