import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { default as Pipe1 } from '../../assets/Pipe1.jpg'
import { default as Compressor } from '../../assets/Compressor.jpg'
import './About.css';
import '../../css/Cards.css';
import '../../App.css';
// import '../testimonials.css';
// import { CardItemWithLabel } from "../CardItem";

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));

export const Aboutv2 = () => {
  return (
      <Container className='extra'>
        <div className='about__container'>
            <Grid container spacing={2}>
                <Grid item xs={4} className='about-img-main-container-v2'>
                    <img src={Compressor} alt="img" className='about-img-main-v2'></img>
                </Grid>
                <Grid item xs={8}>
                    {/* <h1 className='about'>About Us</h1> */}
                    {/* <div className='about__text'> */}
                        <h1 className='about-title-left'>About Us</h1>
                        <p className='about-text-reg'>
                        Founded in 2004 by Tom Davis and Tim Davis, SR/WA, 4D Acquisition and Consulting, LLC brings real estate and right of way solutions to our clients. We support public and private sector energy, transportation and infrastructure projects throughout the US. Our clients include utilities, government agencies, midstream companies, developers and engineers. With services ranging from conceptual planning and cost estimating to acquiring hundreds of miles of easements to construction and operation support we can customize a scope that is perfect for your needs.
                        </p>
                    {/* </div> */}
                </Grid>
            </Grid>
            </div>
            <div className='spacer-sm'></div>
            {/* <div className='about__container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1 className='about-title-center'>4D's PRINCIPLES </h1>
                    </Grid>
                </Grid>
            </div> */}

            <div className='about__container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1 className='about-title-center'>4D's PRINCIPLES </h1>
                    </Grid>
                </Grid>
                <div className='spacer-sm'></div>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                            <div>
                                <h3 className='principle1'>Understanding the project goals.</h3>
                                <p className='about-text-reg indent-level-1'>
                                    While reducing the schedule and budget are always important every project is motivated by key objectives and goals. Aligning our services with your goals helps guide decisions and move a project towards success.
                                </p>
                            </div>
                            {/* </div> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* <h1 className='about'>About Us</h1> */}
                        {/* <div className='about__text'> */}
                            <h3 className='principle2'>Have a plan.</h3>
                            <p className='about-text-reg indent-level-1'>
                            As simple as it sounds, right? Turning a list of necessary tasks into a work plan focuses our efforts, sets expectations, and improves tracking progress.
                            </p>
                        {/* </div> */}
                </Grid>
                    {/* <Grid item xs={4}>
                            <img src={Pipe1} alt="img" className='about-img'></img>
                    </Grid> */}
                </Grid>
            <div className='spacer-sm'></div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                        {/* <h1 className='about'>About Us</h1> */}
                        {/* <div className='about__text'> */}
                            <h3 className='principle3'>Communicate.</h3>
                            <p className='about-text-reg indent-level-1'>
                            Great accomplishments or changing objectives, the information needs to be shared. Regular reporting and open dialogue within our team and with our client’s team helps keep everyone connected and allows quicker adaptation. 
                            </p>
                        {/* </div> */}
                </Grid>
                <Grid item xs={12} md={6}>
                <h3 className='principle4'>People Matter.</h3>
                            <p className='about-text-reg indent-level-1'>
                            The acquisition is the first step in your relationship with a property owner. Having the right team representing your company and project sets a positive tone that the owners will remember. 
                            </p>
                </Grid>
            </Grid>
        </div>
      </Container>
  )
}

// export default About
